import React, {useEffect, useState} from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register the components and plugins
Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const PieChart = (props: {applications: string[], percentage: number[]}) => {
    const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null);
    const [backgroundColors, setBackgroundColors] = useState<string[]>([]);

    const baseOffset = 0;
    const hoverOffset = 10;

    const colorList = [
        '#AA3939',
        '#AA9739',
        '#403075',
        '#2A7E43',
        '#2C4770',
        '#2D882D',
        '#AA5F39',
        '#257059',
        '#7A296B'
    ];

    // Generate the colors once and store them in state
    useEffect(() => {
        const colors = props.percentage.map((value, index) => colorList[index]);
        setBackgroundColors(colors);
    }, [props.percentage]); // Recalculate colors only when the percentage data changes

    // Calculate the offset for each segment based on hover
    const offsets = Array(props.applications.length).fill(baseOffset); // Assuming 6 segments, replace with your actual dataset size
    if (hoveredIndex !== null) {
        offsets[hoveredIndex] = hoverOffset;
    }

    // Generate random colors for each data point
    // const backgroundColors = props.percentage.map(() => getRandomColor());

    const data = {
        labels: props.applications,
        datasets: [
            {
                label: 'Percentage',
                data: props.percentage,
                backgroundColor: backgroundColors,
                borderColor: backgroundColors,
                borderWidth: 1,
                hoverOffset: 35,
                offset: offsets,
            },
        ],

    };

    const options = {
        plugins: {
            tooltip: {
                enabled: false, // Disable tooltips to hide text when hovered
            },
            legend: {
                display: false, // Hide the legend
            },
            datalabels: {
                color: function (context: any) {
                    // When no segment is hovered, all labels are fully opaque.
                    if (hoveredIndex === null) {
                        return 'black';
                    }
                    // Reduce opacity of labels on non-hovered segments.
                    return hoveredIndex === context.dataIndex ? 'black' : 'rgba(0, 0, 0, 0.2)';
                },
                font: (context: any) => {
                    // When a segment is hovered, increase the font size for that label.
                    if (hoveredIndex === context.dataIndex) {
                        return {
                            size: 15, // Larger font size when hovered
                            // weight: 'bold',
                        };
                    }
                    return {
                        size: 10, // Normal font size when not hovered
                        // weight: 'normal',
                    };
                },
                anchor: 'end' as const,
                align: 'end' as const,
                offset: 10,
                borderRadius: 4,
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                borderWidth: 1,
                clip: false, // Prevent labels from being cut off
                // font: {
                //     size: 10, // Decrease font size for better readability
                // },
                // formatter: (value: any) => {
                //     return value.toFixed(2) + '%'; // Display the percentage value
                // },
                formatter: (value: any, context: any) => {
                    const total = context.dataset.data.reduce((acc: number, val: number) => acc + val, 0);
                    const percentage = ((value / total) * 100).toFixed(2);
                    return `${percentage}%`; // Display the percentage value
                },
                // Position labels outside
                labels: {
                    name: {
                        align: 'end' as const,
                        anchor: 'end' as const,
                        formatter: (value: any, context: any) => {
                            return `${value}% - `+context.chart.data.labels[context.dataIndex];
                        },
                    },
                },
            },
            outlabels: {
                text: '%l %p',
                color: 'black',
                stretch: 30,
                font: {
                    resizable: true,
                    minSize: 12,
                    maxSize: 18,
                },
                lineColor: 'black',
                lineWidth: 2,
                padding: 4,
            },
        },
        maintainAspectRatio: false, // Allow flexibility in chart dimensions
        layout: {
            padding: {
                top: 40,
                bottom: 40,
                left: 120,  // Increase left padding
                right: 120, // Increase right padding
            },
        },
        onHover: (event: any, chartElement: any) => {
            if (chartElement.length) {
                setHoveredIndex(chartElement[0].index);
            } else {
                setHoveredIndex(null);
            }
        },
    };

    return <div style={{ width: '100%', height: '200px' }}>
        <Pie data={data} options={options}/>
    </div>;
};

export default PieChart;

// Helper function to generate random color
const getRandomColor = () => {
    // const letters = '0123456789ABCDEF';
    // let color = '#';
    // for (let i = 0; i < 6; i++) {
    //     color += letters[Math.floor(Math.random() * 16)];
    // }
    // return color;

    const colors = [
        '#AA3939',
        '#AA9739',
        '#403075',
        '#2D882D',
        '#2A7E43',
        '#2C4770',
        '#257059',
        '#AA5F39',
        '#7A296B'
    ];
    // Create a copy of the colors array to keep track of remaining colors
    let remainingColors = [...colors];


        // If all colors have been used, reset the remainingColors array
        if (remainingColors.length === 0) {
            remainingColors = [...colors];
        }
        // Select a random index from the remaining colors
        const randomIndex = Math.floor(Math.random() * remainingColors.length);
        // Remove the color from the remaining colors and return it
        const color = remainingColors.splice(randomIndex, 1)[0];
        return color;

};

