import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from 'react-bootstrap'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Formik } from 'formik';
import View from 'asset/SvgComponent/View'
import Write from 'asset/SvgComponent/Write'
import Delete from 'asset/SvgComponent/Delete'
import EditIcon from 'asset/SvgComponent/EditIcon'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import { validationSchema } from './validationSchema'
import {ILeaveConfiguration, IList, IItem, IEmployeeList, IEmployee, ILeaveType, ILeaveCreditList, ILeaveCredit} from './types'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import moment from "moment/moment";
import Select from "react-select";


export default function Index() {
  const [key, setKey] = useState('leave-types')
  const [dateFormat, setDateFormat] = useState('')

  const [currentPage, setCurrentPage] = React.useState(0)
  const [lastPage, setLastPage] = React.useState(0)
  const [itemsPerPage, setPerPage] = React.useState(10)
  const [itemsCount, setItemCount] = React.useState(0)
  const [from, setFrom] = React.useState(0)

  const [currentPage2, setCurrentPage2] = React.useState(0)
  const [lastPage2, setLastPage2] = React.useState(0)
  const [itemsPerPage2, setPerPage2] = React.useState(10)
  const [itemsCount2, setItemCount2] = React.useState(0)
  const [from2, setFrom2] = React.useState(0)

  const [currentPage3, setCurrentPage3] = React.useState(0)
  const [lastPage3, setLastPage3] = React.useState(0)
  const [itemsPerPage3, setPerPage3] = React.useState(10)
  const [itemsCount3, setItemCount3] = React.useState(0)
  const [from3, setFrom3] = React.useState(0)

  const [currentPage4, setCurrentPage4] = React.useState(0)
  const [lastPage4, setLastPage4] = React.useState(0)
  const [itemsPerPage4, setPerPage4] = React.useState(10)
  const [itemsCount4, setItemCount4] = React.useState(0)
  const [from4, setFrom4] = React.useState(0)

  const [validation, setValidation] = React.useState('')
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showLoading, setShowLoading] = React.useState(false)

  const [showCreate, setShowCreate] = React.useState(false)
  const handleCloseCreate = () => setShowCreate(false)
  const handleShowCreate = () => {
    setNumOfDayDisabled(false)
    setShowCreate(true)
  }


  const [showAssign, setShowAssign] = React.useState(false)
  const handleCloseAssign = () => setShowAssign(false)
  const handleShowAssign = () => {
    setShowAssign(true)
    setIncludedEmployees([])
    getAllEmployees(null, selectedOptions)
    const newLeaveTypes = leaveTypes.map(leave => {
      return {...leave, num_of_day: "0"};
    });

    setLeaveTypes(newLeaveTypes);
  }

  const [showEmployees, setShowEmployees] = React.useState(false)
  const handleCloseEmployees = () => setShowEmployees(false)
  const handleShowEmployees = () => setShowEmployees(true)

  const [showEdit, setShowEdit] = React.useState(false)
  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)

  const [showConfirm, setShowConfirm] = React.useState(false)
  const handleCloseConfirm = () => setShowConfirm(false)
  const handleShowConfirm = (id: any) => {
    setShowConfirm(true)
    setItem({
      leave_type_id: id,
      leave_type_name: '',
      num_of_day: '',
      leave_description: '',
      is_unlimited: 0
    })
  }

  const [lists, setData] = useState<IList[]>([])
  const [item, setItem] = useState<IItem>({
    leave_type_id: 0,
    leave_type_name: '',
    num_of_day: '',
    leave_description: '',
    is_unlimited: 0
  })
  const [leaveTypes, setLeaveTypes] = useState<ILeaveType[]>([])
  const [employees, setEmployees] = useState<IEmployee[]>([])
  const [employeeSelected, setEmployeeSelected] = useState(0)
  const [includedEmployees, setIncludedEmployees] = useState<number[]>([])
  const [leaveCreditHistory, setLeaveCreditHistory] = useState<ILeaveCredit[]>([])
  const [leaveCreditHistoryId, setLeaveCreditHistoryId] = useState(0)
  const [employeesAssign, setEmployeesAssign] = useState<IEmployee[]>([])
  const [selectedOptions, setSelectedOptions] = useState<string[]>(['']);
  const [selectedOptionsValue, setSelectedOptionsValue] = useState<{value: string, label: string}[]>([{value: "all", label: "All Options"}]);
  const [filterOptions, setFilterOptions] = useState<{value: string, label: string, isDisabled: boolean}[]>([])
  const [numOfDayDisabled, setNumOfDayDisabled] = useState(false)
  const [numOfDayEditDisabled, setNumOfDayEditDisabled] = useState(false)

  const getFilterOptions = async() => {

    try {
      const res = await axios.get<IAPI_Response<{value: string, label: string, isDisabled: boolean}[]>>(API_ENDPOINT.GET_EMPLOYEE_FILTER_OPTION)
      const result = await res
      if (result?.data?.results) {
        const options = result?.data?.results
        options.map(option => {
          if(option.value == ""){
            option.isDisabled = true
          }else{
            option.isDisabled = false
          }
        })
        setFilterOptions(options)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }



  const handleShowListOfEmployees = async(page_number : number | null, id: number) => {
    handleShowEmployees()
    setLeaveCreditHistoryId(id)
    try {
      const res = await axios.get<IAPI_Response<IEmployeeList>>(API_ENDPOINT.GET_EMPLOYEES_ASSIGNED+"/"+id, {
        params: {
          page: page_number,
        },
      })
      const result = await res
      if (result?.data?.results?.data?.length) {
        setEmployeesAssign(result?.data?.results?.data)
        setCurrentPage4(result?.data?.results?.current_page)
        setLastPage4(result?.data?.results?.last_page)
        setPerPage4(result?.data?.results?.per_page)
        setItemCount4(result?.data?.results?.total)
        setFrom4(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const handleChangeLeaveCredits = (e: any, id: number) => {
    const newLeaveTypes = leaveTypes.map(leave => {
      if (leave.leave_type_id == id) {
        return {...leave, num_of_day: e.target.value};
      }
      return leave;
    });

    setLeaveTypes(newLeaveTypes);
  }

  const handleCheckboxChange = (employeeId: number, e: any) => {
    const newEmployees = employees.map(employee => {
      if (employee.employee_id == employeeId) {
        if(!e.target.checked){
          setEmployeeSelected(employeeSelected - 1)
          setIncludedEmployees(current =>
              current.filter(employee_id => {
                // 👇️ remove object that has id equal to 2
                return employee_id !== employeeId;
              }),
          );
        }else{
          setEmployeeSelected(employeeSelected + 1)
          setIncludedEmployees(current => [...current, employeeId])
        }

        return {...employee, include: e.target.checked ? 1 : 0};
      }
      return employee;
    });

    setEmployees(newEmployees);
  }

  const handleCheckAllCheckBox = (e: any) => {
    const newEmployees = employees.map(employee => {

      if(!e.target.checked){
        setIncludedEmployees(current =>
            current.filter(employee_id => {
              // 👇️ remove object that has id equal to 2
              return employee_id !== employee.employee_id;
            }),
        );
      }else{
        if (!(includedEmployees.filter(e => e === employee.employee_id).length > 0)) {
          setIncludedEmployees(current => [...current, employee.employee_id])
        }

      }
      return {...employee, include: e.target.checked ? 1 : 0};

    });


    setEmployees(newEmployees);
  }

  const handleAssignLeaveCredit = async () => {
    await axios.post(API_ENDPOINT.ASSIGN_LEAVE_CREDIT, {
      included_employees: includedEmployees,
      leave_types: leaveTypes,
    }).then(response => {
      if (response?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.LEAVE_CONFIGURATION.CREATED)
        // handleShowListOfEmployees(null, leaveCreditHistoryId)
        getLeaveCreditsHistory(null)
        handleCloseAssign()
      }
    }).catch(error => {
      setValidation(error.response.data.message)
      setShowError(true)
      setError(error.response.data.message)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const getAllLeaveTypes = async () => {
    try {
      const res = await axios.get<IAPI_Response<ILeaveType[]>>(API_ENDPOINT.ALL_LEAVE_CONFIGURATION)
      const result = await res
      if (result?.data?.results?.length) {
        const leave_types = result?.data?.results
        leave_types.map((type: ILeaveType) => {
          type.num_of_day = "0";
        })
        setLeaveTypes(leave_types)
      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const getAllEmployees = async (page_number: null | number, filters: string[]) => {
    try {
      const res = await axios.get<IAPI_Response<IEmployeeList>>(API_ENDPOINT.GET_EMPLOYEE_FOR_LEAVE_CREDITS, {
        params: {
          page: page_number,
          filter: filters
        },
      })
      const result = await res
      if (result?.data?.results?.data?.length) {
        const employeeList = result?.data?.results?.data
        employeeList.map(employee => {
          includedEmployees.map(included => {
            if(employee.employee_id == included) {
              employee.include = 1
            }
          })
        })

        setEmployees(employeeList)
        setCurrentPage2(result?.data?.results?.current_page)
        setLastPage2(result?.data?.results?.last_page)
        setPerPage2(result?.data?.results?.per_page)
        setItemCount2(result?.data?.results?.total)
        setFrom2(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getLeaveCreditsHistory = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const res = await axios.get<IAPI_Response<ILeaveCreditList>>(API_ENDPOINT.GET_LEAVE_CREDIT_HISTORY, {
        params: {
          page: page_number,
        },
      })
      const result = await res
      if (result?.data?.results?.data?.length) {
        setLeaveCreditHistory(result?.data?.results?.data)
        setCurrentPage3(result?.data?.results?.current_page)
        setLastPage3(result?.data?.results?.last_page)
        setPerPage3(result?.data?.results?.per_page)
        setItemCount3(result?.data?.results?.total)
        setFrom3(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getLeaveConfiguration = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const res = await axios.get<IAPI_Response<ILeaveConfiguration>>(API_ENDPOINT.GET_LEAVE_CONFIGURATION, {
        params: {
          page: page_number,
        },
      })
      const result = await res
      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showLeaveConfiguration = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_LEAVE_CONFIGURATION +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItem(result?.data?.results)
        if(result?.data?.results.is_unlimited == 0){
          setNumOfDayEditDisabled(false)
        }else{
          setNumOfDayEditDisabled(true)
        }
      }
      handleShowEdit()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const postLeaveConfiguration = async (value: any, {resetForm} : any) => {
    await axios.post(API_ENDPOINT.POST_LEAVE_CONFIGURATION, {
      leave_type_name: value.leave_type_name,
      num_of_day: value.num_of_day,
      leave_description: value.leave_description,
      is_unlimited: value.limit
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.LEAVE_CONFIGURATION.CREATED)
          getLeaveConfiguration(null);
       }
       resetForm({})
       handleCloseCreate();
    }).catch(error => {
       setValidation(error.response.data.message)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
      setValidation('')
    }, 3000);
  }

  const updateLeaveConfiguration = async (value: any, {resetForm} : any) => {
    await axios.put(API_ENDPOINT.PUT_LEAVE_CONFIGURATION +'/'+ value.leave_type_id, {
      leave_type_name: value.leave_type_name,
      num_of_day: value.num_of_day,
      leave_description: value.leave_description,
      is_unlimited: value.is_unlimited
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.LEAVE_CONFIGURATION.UPDATED)
          getLeaveConfiguration(null);
       }
       resetForm({})
       handleCloseEdit();
    }).catch(error => {
      setValidation(error.response.data.message)
    })
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
      setValidation('')
    }, 3000);
  }

  const deleteLeaveConfiguration = async (id: null | number) => {
    try {
      const response = await axios.delete(API_ENDPOINT.DELETE_LEAVE_CONFIGURATION +'/'+ id)
      const result = await response
      if (result?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.LEAVE_CONFIGURATION.DELETED)
        getLeaveConfiguration(null);
     }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
    handleCloseConfirm()
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getLeaveConfiguration(null)

      getAllLeaveTypes()
      getLeaveCreditsHistory(null)
      getFilterOptions()
      setDateFormat(localStorage.getItem('date_format') ?? "")
    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Leave Configuration</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='/leaves/holiday-management'>
            Leaves
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Leave Configuration</Breadcrumb.Item>
        </Breadcrumb>

        <Alert 
          type="success" 
          show={showSuccess} 
          message={success} 
          setShow={() => { 
              setShowSuccess(false);
            }
          }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />
        <Tabs
            variant={'tabs'}
            className='tabs'
            id='controlled-tab-example'
            activeKey={key}
            onSelect={(k: any) => {
              setKey(k)
            }}
            // sx={{
            //   opacity: open ? 1 : 0,
            //   fontWeight: openDropdown === 0 ? 700 : 100,
            // }}
        >
          <Tab  eventKey='leave-types' title='Leave Types'>
            <Card className='border-0  p-0 bordered-card-tabs'>
              <Card.Body>
                <Row>
                  <Col sm={6}>
                    <span className='fw-bold'>Leave Types</span>
                  </Col>
                  <Col sm={6} className='d-flex justify-content-end hidden'>
                    <InputGroup size='sm' className={'w-25'}>
                      <Form.Control
                          aria-label='search'
                          aria-describedby='inputGroup-sizing-sm'
                          placeholder='Search...'
                      />
                      <InputGroup.Text className='bg-transparent search-input '>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                    <Button
                        onClick={handleShowCreate}
                        className=' add-new-btn rounded mx-2 text-white'
                        variant='warning'
                    >
                      <EditIcon/>
                      &nbsp;Add Leave Configuration
                    </Button>{' '}
                  </Col>
                </Row>
                <Table responsive className='bordered-tabs-top mt-3'>
                  <thead>
                  <tr className='text-center'>
                    <th style={{ width: '5%' }}>No.</th>
                    <th style={{ width: '15%' }}>Leave Name</th>
                    <th style={{ width: '25%' }}>Leave Description</th>
                    <th style={{ width: '15%' }}>Limit</th>
                    <th style={{ width: '15%' }}>No. of Leaves</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <>
                    {lists.length > 0 ? (
                        lists.map((item, index) =>
                            <tr className='mb-2' key={index}>
                              <td>{from + index}</td>
                              <td>{item.leave_type_name}</td>
                              <td>{item.leave_description}</td>
                              <td className="text-center">{item.is_unlimited == 0 ? "Limited" : "Unlimited"}</td>
                              <td className="text-center">{item.is_unlimited == 0 ? item.num_of_day : "-"}</td>
                              <td className='text-center'>
                                <Button
                                    onClick={() => showLeaveConfiguration(item.leave_type_id)}
                                    className=' p-1 pt-0 pb-0'
                                    style={{ fontSize: '12px', color: 'white' }}
                                    variant=' btn-transparent'
                                    size='sm'
                                >
                                  <Write/>
                                </Button>
                                <Button
                                    onClick={() => handleShowConfirm(item.leave_type_id)}
                                    className=' p-1 pt-0 pb-0'
                                    style={{ fontSize: '12px', color: 'white' }}
                                    variant=' btn-transparent'
                                    size='sm'
                                >
                                  <Delete/>
                                </Button>
                              </td>
                              <br />
                            </tr>
                        )
                    ) : (
                        <tr className='no-item'>
                          <td colSpan={6}>{TEXT.NOT_FOUND}</td>
                        </tr>
                    )}
                  </>
                  </tbody>
                </Table>
                <Pagination
                    itemsCount={itemsCount}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    lastPage={lastPage}
                    setCurrentPage={setCurrentPage}
                    alwaysShown={false}
                    pageClicked={(page: number) => {
                      getLeaveConfiguration(page);
                    }
                    }
                />

                <Dialog
                    show={showConfirm}
                    setShow={handleCloseConfirm}
                    setEvent={() => {
                      deleteLeaveConfiguration(item.leave_type_id)
                    }
                    }
                />

                <Loader show={showLoading}/>
              </Card.Body>
            </Card>
          </Tab>
          <Tab  eventKey='leave-credits' title='Leave Credits'>
            <Card className='border-0  p-0 bordered-card-tabs'>
              <Card.Body>
                <Row>
                  <Col sm={6}>
                    <span className='fw-bold'>Leave Credits History</span>
                  </Col>
                  <Col sm={6} className='d-flex justify-content-end hidden'>
                    <InputGroup size='sm' className={'w-25'}>
                      <Form.Control
                          aria-label='search'
                          aria-describedby='inputGroup-sizing-sm'
                          placeholder='Search...'
                      />
                      <InputGroup.Text className='bg-transparent search-input '>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                    <Button
                        onClick={handleShowAssign}
                        className=' add-new-btn rounded mx-2 text-white'
                        variant='warning'
                    >
                      <EditIcon/>
                      &nbsp;Assign Leave Credit
                    </Button>{' '}
                  </Col>
                </Row>
                <Table responsive className='bordered-tabs-top mt-3'>
                  <thead>
                  <tr className='text-center'>
                    <th style={{ width: '5%' }}>No.</th>
                    <th style={{ width: '15%' }}>No. of Employees Assign</th>
                    {
                      leaveTypes.map((type, index) => (
                          <th key={index}>{type.leave_type_name}</th>
                      ))
                    }
                    <th style={{width: '15%'}}>Assigned By</th>
                    <th style={{width: '15%'}}>Assigned On</th>
                  </tr>
                  </thead>
                  <tbody>
                  <>
                    {leaveCreditHistory.length > 0 ? (
                        leaveCreditHistory.map((history, index) =>
                            <tr className='mb-2' key={index}>
                              <td>{from3 + index}</td>
                              <td className="display-employees" onClick={() => handleShowListOfEmployees(null, history.id)}>{history.employees_include.length} Employees</td>
                              {
                                leaveTypes.map((type, index) => (
                                    history.leave_type.some(item => type.leave_type_id === item.leave_type_id) ?
                                      history.leave_type.map((type2, index2) => (
                                          type.leave_type_id == type2.leave_type_id ?
                                            <td className="text-center" key={index}>{type2.num_of_day} Days</td>
                                              : ""
                                      )) :
                                        <td className="text-center">0 Days</td>


                                ))
                              }
                              <td>{history.created_by_first_name} {history.created_by_last_name}</td>
                              <td className='text-center'>{moment(new Date(history.created_at ?? "")).format(dateFormat)}</td>

                            </tr>
                        )
                    ) : (
                        <tr className='no-item'>
                          <td colSpan={11}>{TEXT.NOT_FOUND}</td>
                        </tr>
                    )}
                  </>
                  </tbody>
                </Table>
                <Pagination
                    itemsCount={itemsCount3}
                    itemsPerPage={itemsPerPage3}
                    currentPage={currentPage3}
                    lastPage={lastPage3}
                    setCurrentPage={setCurrentPage3}
                    alwaysShown={false}
                    pageClicked={(page: number) => {
                      getLeaveCreditsHistory(page);
                    }
                    }
                />
              </Card.Body>
            </Card>
          </Tab>
        </Tabs>


        {/* ADD MODAL*/}
        <Formik
            initialValues={
              { 
                leave_type_name: '',
                num_of_day: '',
                leave_description: '',
                limit: "0"
              }
            }
            validationSchema={validationSchema}
            onSubmit={postLeaveConfiguration}
            >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <Form id="leaveConfigurationCreate" noValidate onSubmit={handleSubmit}>
                <Modal className='modal' centered show={showCreate} onHide={handleCloseCreate}>
                  <Modal.Header closeButton>
                    <Modal.Title className='fw-bold'>Add New Leave Type</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                            <Form.Label className='text-muted fw-bold required'>Leave Name</Form.Label>
                            <Form.Control 
                              size={'sm'} 
                              type='text' 
                              placeholder='Leave Name'
                              name="leave_type_name"
                              value={values.leave_type_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.leave_type_name && !!errors.leave_type_name || !!validation}
                              />
                              {
                              validation != '' ?
                                (Object.keys(validation).map((message : any, index) => (
                                  <Form.Control.Feedback type="invalid" key={index}>
                                    {validation[message][index]}
                                  </Form.Control.Feedback>
                                ))
                                ) : (
                                <Form.Control.Feedback type="invalid">
                                  {errors.leave_type_name}
                                </Form.Control.Feedback>
                                )
                              }
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                            <Form.Label className='text-muted fw-bold required'>Leave Description</Form.Label>
                            <Form.Control
                                size={'sm'}
                                type='text'
                                placeholder='Leave Description'
                                name="leave_description"
                                value={values.leave_description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.leave_description && !!errors.leave_description || !!validation}
                            />
                            {
                              validation != '' ?
                                  (Object.keys(validation).map((message : any, index) => (
                                          <Form.Control.Feedback type="invalid" key={index}>
                                            {validation[message][index]}
                                          </Form.Control.Feedback>
                                      ))
                                  ) : (
                                      <Form.Control.Feedback type="invalid">
                                        {errors.leave_description}
                                      </Form.Control.Feedback>
                                  )
                            }
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                            <Form.Label className='text-muted fw-bold required'>Limit</Form.Label>
                            <Form.Select
                                size={'sm'}
                                name="limit"
                                onChange={(e) => {
                                  handleChange(e)
                                  if(e.target.value == "0"){
                                    setNumOfDayDisabled(false)
                                  }else{
                                    setNumOfDayDisabled(true)
                                    values.num_of_day = "0";
                                  }

                                }}
                                onBlur={handleBlur}
                                isInvalid={touched.limit && !!errors.limit || !!validation}
                            >
                              <option value="0">Limited</option>
                              <option value="1">Unlimited</option>
                            </Form.Select>
                            <Form.Control.Feedback className="left" type="invalid">
                              {errors.limit}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                            <Form.Label className='text-muted fw-bold required'>No. of Days</Form.Label>
                            <Form.Control
                                size={'sm'}
                                type='number'
                                step='.5'
                                placeholder='0'
                                name="num_of_day"
                                value={values.num_of_day}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.num_of_day && !!errors.num_of_day}
                                disabled={numOfDayDisabled}
                            />
                            <Form.Control.Feedback className="left" type="invalid">
                              {errors.num_of_day}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant='outline-primary' size={'sm'} onClick={handleCloseCreate}>
                      Cancel
                    </Button>
                    <Button type="submit" disabled={isSubmitting} form="leaveConfigurationCreate" variant='primary text-white' className='mx-2' size={'sm'}>
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Form>
            )}
        </Formik>

        {/* EDIT MODAL*/}
        <Formik
            initialValues={item}
            validationSchema={validationSchema}
            onSubmit={updateLeaveConfiguration}
            enableReinitialize
            >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <Form id="leaveConfigurationUpdate" noValidate onSubmit={handleSubmit}>
                <Modal className='modal' centered show={showEdit} onHide={handleCloseEdit}>
                  <Modal.Header closeButton>
                    <Modal.Title className='fw-bold'>Edit Leave Configuration</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                            <Form.Label className='text-muted fw-bold required'>Leave Name</Form.Label>
                            <Form.Control 
                              size={'sm'} 
                              type='text' 
                              placeholder='Leave Name'
                              name="leave_type_name"
                              value={values.leave_type_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.leave_type_name && !!errors.leave_type_name || !!validation}
                              />
                              {
                              validation != '' ?
                                (Object.keys(validation).map((message : any, index) => (
                                  <Form.Control.Feedback type="invalid" key={index}>
                                    {validation[message][index]}
                                  </Form.Control.Feedback>
                                ))
                                ) : (
                                <Form.Control.Feedback type="invalid">
                                  {errors.leave_type_name}
                                </Form.Control.Feedback>
                                )
                              }
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                            <Form.Label className='text-muted fw-bold required'>Leave Description</Form.Label>
                            <Form.Control
                                size={'sm'}
                                type='text'
                                placeholder='Leave Description'
                                name="leave_description"
                                value={values.leave_description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.leave_description && !!errors.leave_description || !!validation}
                            />
                            {
                              validation != '' ?
                                  (Object.keys(validation).map((message : any, index) => (
                                          <Form.Control.Feedback type="invalid" key={index}>
                                            {validation[message][index]}
                                          </Form.Control.Feedback>
                                      ))
                                  ) : (
                                      <Form.Control.Feedback type="invalid">
                                        {errors.leave_description}
                                      </Form.Control.Feedback>
                                  )
                            }
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                            <Form.Label className='text-muted fw-bold required'>Limit</Form.Label>
                            <Form.Select
                                size={'sm'}
                                name="is_unlimited"
                                value={values.is_unlimited}
                                onChange={(e) => {
                                  handleChange(e)
                                  if(e.target.value == "0"){
                                    setNumOfDayEditDisabled(false)
                                  }else{
                                    setNumOfDayEditDisabled(true)
                                    values.num_of_day = "0";
                                  }

                                }}
                                onBlur={handleBlur}
                                isInvalid={touched.is_unlimited && !!errors.is_unlimited || !!validation}
                            >
                              <option value="0">Limited</option>
                              <option value="1">Unlimited</option>
                            </Form.Select>
                            <Form.Control.Feedback className="left" type="invalid">
                              {errors.is_unlimited}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                            <Form.Label className='text-muted fw-bold required'>No. of Days</Form.Label>
                            <Form.Control
                                size={'sm'}
                                type='number'
                                step='.5'
                                placeholder='0'
                                name="num_of_day"
                                value={values.num_of_day}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.num_of_day && !!errors.num_of_day}
                                disabled={numOfDayEditDisabled}
                            />
                            <Form.Control.Feedback className="left" type="invalid">
                              {errors.num_of_day}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
                      Cancel
                    </Button>
                    <Button type="submit" disabled={isSubmitting} form="leaveConfigurationUpdate" variant='primary text-white' className='mx-2' size={'sm'}>
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Form>
            )}
        </Formik>
        {/* EMPLOYEE LIST ASSIGN LEAVE CREDITS MODAL*/}
        <Modal className='modal' centered show={showAssign} onHide={handleCloseAssign}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Add/Subtract Leave Credit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              leaveTypes.map((leave, index) => (
                  <Row key={index} className="mb-4">
                    <Form.Group as={Col} md="4">
                      <Form.Label column="sm" >{leave.leave_type_name}</Form.Label>
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="position-relative">
                      <Form.Control
                          className="text-right"
                          size={'sm'}
                          type='number'
                          placeholder='0'
                          name="sick_leave"
                          value={leave.num_of_day}
                          onChange={(e: any) => handleChangeLeaveCredits(e, leave.leave_type_id)}
                      />
                    </Form.Group>
                  </Row>
              ))
            }
            <Row>
              <Col sm={9}>
                <Form.Group className='mb-3'>
                  <Form.Label>Filter: </Form.Label>
                  <Select
                      placeholder={"Select Filter"}
                      defaultValue={selectedOptionsValue}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      onChange={(options : any) => {
                        if (Array.isArray(options)) {
                          let filterOptionsArr :string[] = [];
                          let allOptions = false;
                          options.map(opt => {
                            filterOptionsArr.push(opt.value)

                            if(opt.value == "all"){
                              allOptions = true;
                            }
                          })

                          if(allOptions){
                            filterOptionsArr = [];
                            filterOptions.map(filterOpt => {
                              if(filterOpt.value != ""){
                                if(filterOpt.value != "all"){
                                  filterOptionsArr.push(filterOpt.value)

                                }

                              }

                            })
                            setSelectedOptionsValue(filterOptions)
                          }

                          setSelectedOptions(filterOptionsArr)
                          getAllEmployees(null, filterOptionsArr)
                        }

                      }}
                      // @ts-ignore
                      options={filterOptions}
                      id="filter-option"
                      styles={{
                        option: (base) => ({
                          ...base,
                          height: '80%',
                          fontSize: '12px'
                        }),
                      }}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group style={{textAlign:'right'}}>
                  <Form.Label column="sm" >{includedEmployees.length} selected</Form.Label>
                </Form.Group>
              </Col>
            </Row>


            <Table responsive className='bordered-tabs-top mt-3'>
              <thead>
              <tr className='text-center'>
                <th style={{ width: '10%' }}>
                  <Form.Group>
                    <Form.Check
                        label={"All"}
                        name={"all_employees"}
                        value={"all_employees"}
                        id={"all_employees"}
                        // checked={menu.isChecked === 1}
                        onClick={(e: any) => handleCheckAllCheckBox(e)}
                        // isInvalid={touched.gender && !!errors.gender}
                    />
                  </Form.Group>
                </th>
                <th style={{ width: '30%' }}>Name</th>
                <th style={{ width: '20%' }}>Designation</th>
                <th style={{ width: '20%' }}>Department</th>
                <th style={{ width: '20%' }}>Type</th>
              </tr>
              </thead>
              <tbody>
              {
                employees.length > 0 ? (
                    employees.map((employee, index) => (
                        <tr className='mb-2' key={employee.employee_id}>
                          <td>
                            <Form.Group key={employee.employee_id}>
                              <Form.Check
                                  // label={menu.name}
                                  name={employee?.employee_id?.toString()}
                                  value={employee.employee_id?.toString()}
                                  id={employee?.employee_id?.toString()}
                                  checked={employee?.include == 1}
                                  onClick={(event) => handleCheckboxChange(employee.employee_id, event)}
                              />
                            </Form.Group>
                          </td>
                          <td >{employee.last_name + ", " +employee.first_name }</td>
                          <td>{employee.designation.designation_name}</td>
                          <td>{employee.department.department_name}</td>
                          <td>{employee.employee_type == "0" ? "Probationary": "Regular"}</td>
                        </tr>
                    ))
                ) : (
                    <tr className='no-item'>
                      <td colSpan={3}>{TEXT.NOT_FOUND}</td>
                    </tr>
                )

              }


              </tbody>
            </Table>
            <Pagination
                itemsCount={itemsCount2}
                itemsPerPage={itemsPerPage2}
                currentPage={currentPage2}
                lastPage={lastPage2}
                setCurrentPage={setCurrentPage2}
                alwaysShown={false}
                pageClicked={(page: number) => {
                  getAllEmployees(page, selectedOptions);
                }
                }
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' size={'sm'} onClick={handleCloseAssign}>
              Cancel
            </Button>
            <Button
                variant='primary text-white'
                size={'sm'}
                className='mx-2'
                onClick={handleAssignLeaveCredit}
                disabled={includedEmployees.length == 0 ? true: false}
            >
              Assign
            </Button>
          </Modal.Footer>
        </Modal>

        {/* EMPLOYEE LIST MODAL*/}
        <Modal className='modal' centered show={showEmployees} onHide={handleCloseEmployees}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Employee List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table responsive className='bordered-tabs-top mt-3'>
              <thead>
              <tr className='text-center'>
                <th style={{ width: '10%' }}>No.</th>
                <th style={{ width: '30%' }}>Name</th>
                <th style={{ width: '20%' }}>Designation</th>
                <th style={{ width: '20%' }}>Department</th>
                <th style={{ width: '20%' }}>Type</th>
              </tr>
              </thead>
              <tbody>
              {
                employeesAssign.length > 0 ? (
                    employeesAssign.map((employee, index) => (
                        <tr className='mb-2' key={employee.employee_id}>
                          <td>{from4 + index}</td>
                          <td >{employee.last_name + ", " +employee.first_name }</td>
                          <td>{employee.designation?.designation_name}</td>
                          <td>{employee.department?.department_name}</td>
                          <td>{employee.employee_type == "0" ? "Probationary": "Regular"}</td>
                        </tr>
                    ))
                ) : (
                    <tr className='no-item'>
                      <td colSpan={2}>{TEXT.NOT_FOUND}</td>
                    </tr>
                )

              }


              </tbody>
            </Table>
            <Pagination
                itemsCount={itemsCount4}
                itemsPerPage={itemsPerPage4}
                currentPage={currentPage4}
                lastPage={lastPage4}
                setCurrentPage={setCurrentPage4}
                alwaysShown={false}
                pageClicked={(page: number) => {
                  handleShowListOfEmployees(page, leaveCreditHistoryId);
                }
                }
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' size={'sm'} onClick={handleCloseEmployees}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <br />
      </Container>
    </>
  )
}
